import times from 'lodash/times'
import startCase from 'lodash/startCase'
import format from 'date-fns/format'
import isMatch from 'date-fns/isMatch'
import parse from 'date-fns/parse'
import locale from 'date-fns/locale/en-US'

export const cleanPhoneNumberInput = (number) => ('' + number).replace(/\D/g, '').substring(0, 10)

export const cleanNumberInput = (number) => ('' + number).replace(/\D/g, '')
export const phoneFormat = (number) => {
  const clean = cleanNumberInput(number)
  const match = clean.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }
}

export const phoneInputFormat = (number) => {
  const clean = cleanNumberInput(number)
  const area = clean.substring(0, 3)
  const exchange = clean.substring(3, 6)
  const remain = clean.substring(6, 10)

  if (clean.length > 6) {
    return `(${area}) ${exchange}-${remain}`
  }
  if (clean.length > 3) {
    return `(${area}) ${exchange}`
  }
  if (clean.length) {
    return `(${area}`
  }
}

export const formatDate = (date) => {
  if (date) {
    let finalDate

    switch (typeof date) {
      case 'number':
        finalDate = new Date(date)
        break
      case 'string':
        if (isMatch(date, 'y-MM-dd')) {
          finalDate = parse(date, 'y-MM-dd', new Date())
        } else {
          finalDate = new Date(date)
          if (isNaN(finalDate)) {
            throw new Error(`Wrong date format; date: ${date};`)
          }
        }
        break
      case 'object':
        if (date instanceof Date) {
          finalDate = date
        } else {
          throw new Error(`Date object must be instanceof Date. Got: ${date.constructor}`)
        }
        break
      default:
        throw new Error(`Date must be string or number. Got: ${typeof date}`)
    }

    return format(finalDate, 'MM/dd/y')
  }

  return ''
}

export const getWeekDays = (width = 'wide') => times(7, (i) => locale.localize.day(i, { width }))

export const getServiceTypeLabel = (serviceType) =>
  startCase(serviceType)
    .replace('Covid 19', 'COVID-19')
    .replace('Timelycare', 'TimelyCare')
    .replace('Obgyn', 'Ob/gyn')
    .replace('Outpatient Partial', 'Outpatient/Partial')

export const getCurrentTimezoneName = (timeZoneName = 'long') =>
  new Intl.DateTimeFormat('en-US', { timeZoneName })
    .formatToParts(new Date())
    .find((part) => part.type === 'timeZoneName')?.value || ''
